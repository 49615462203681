import { AUTH, IS_ADMIN, CREATE_AUTH_ERROR, ACCOUNT } from '../constants/types';
import * as api from '../apis/db.js';

export const signin = (formData, router) => async (dispatch) => {
  try {
    const { data } = await api.signIn(formData);
    dispatch({ type: AUTH, data });
    // console.log(data, 'data from the login')
    if (data.userRivers.length < 1) {
      router.push('/regions');
    } else {
      router.push('/selected');
    }

  } catch ({ response }) {
    dispatch({ type: CREATE_AUTH_ERROR, response });
  }
};

export const signup = (formData, router) => async (dispatch) => {
  try {
    const { data } = await api.signUp(formData);
    dispatch({ type: AUTH, data });

    router.push('/regions');
  } catch ({ response }) {
    dispatch({ type: CREATE_AUTH_ERROR, response });
  }
};

export const reset = (formData, router) => async (dispatch) => {
  try {
    const { data } = await api.reset(formData);
    dispatch({ type: ACCOUNT, data });

    router.push('/auth');
  } catch ({ response }) {
    // console.log(response, 'hey response from the ponce')
    // if (response?.contains('token')) {
    //   router.push('/forgot')
    // }
    dispatch({ type: CREATE_AUTH_ERROR, response });
  }
};

export const forgot = (formData, router) => async (dispatch) => {
  try {
    const { data } = await api.forgot(formData);
    dispatch({ type: ACCOUNT, data });

    // router.push('/regions');
  } catch ({ response }) {
    dispatch({ type: CREATE_AUTH_ERROR, response });
  }
};

export const googlelogin = (formData, router) => async (dispatch) => {
  const adminEmail = process.env.REACT_APP_ADMIN_EMAIL;
  try {
    const { data } = await api.googleLogin(formData);

    if (data?.result?.email === adminEmail) {
      dispatch({ type: IS_ADMIN, payload: true });
    }
    dispatch({ type: AUTH, data });
    if (!data.userRivers || data.userRivers?.length < 1) {
      router.push('/regions');
    } else {
      router.push('/selected');
    }
  } catch ({ response }) {
    dispatch({ type: CREATE_AUTH_ERROR, response });
  }
};
