import { FETCH_ALL_RIVERS, FETCH_ALL_RIVERLISTS, CREATE_RIVERLIST, UPDATE_RIVERLIST, DELETE_RIVERLIST_ID } from '../constants/types';
import * as api from '../apis/db.js';
import { deleteAllSelectedRivers } from './selectedRivers';

export const getRivers = () => async (dispatch) => {
  try {
    const { data } = await api.fetchRivers();
    dispatch({ type: FETCH_ALL_RIVERS, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const getRiverList = () => async (dispatch) => {
  try {
    const { data } = await api.fetchRiverList();
    dispatch({ type: FETCH_ALL_RIVERLISTS, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const createRiverList = (riverIdArr, history) => async (dispatch) => {

  try {
    const { data } = await api.createRiverList(riverIdArr);
    // console.log(data, 'data from create river list')
    dispatch({ type: CREATE_RIVERLIST, payload: data });
    history.push('/selected')
  } catch (error) {
    console.log(error);
  }
};

export const updateRiverList = (id, riverIdArr, history) => async (dispatch) => {
  try {
    const { data } = await api.updateRiverList(id, riverIdArr);
    // console.log(data, 'data from update river list')
    dispatch({ type: UPDATE_RIVERLIST, payload: data });
    dispatch(deleteAllSelectedRivers());
    history.push('/selected')
  } catch (error) {
    console.log(error);
  }
};

export const deleteRiverListId = (id, riverId) => async (dispatch) => {
  try {
    const { data } = await api.deleteRiverListId(id, riverId);
    // console.log(data, 'data from delete river list')
    dispatch({ type: DELETE_RIVERLIST_ID, payload: data });
  } catch (error) {
    console.log(error);
  }
};
