export const FETCH_RIVER = 'FETCH_RIVER';
export const CREATE_RIVER = 'CREATE_RIVER';
export const UPDATE_RIVER = 'UPDATE_RIVER';
export const DELETE_RIVER = 'DELETE_RIVER';
export const FETCH_ALL_RIVERS = 'FETCH_ALL_RIVERS';

export const CREATE_RIVERLIST = 'CREATE_RIVERLIST';
export const UPDATE_RIVERLIST = 'UPDATE_RIVERLIST';
export const DELETE_RIVERLIST_ID = 'DELETE_RIVERLIST_ID';
export const FETCH_ALL_RIVERLISTS = 'FETCH_ALL_RIVERLISTS';

export const UPDATE_SELECTED = 'UPDATE_SELECTED';
export const DELETE_SELECTED = 'DELETE_SELECTED';
export const DELETE_ALL_SELECTED = 'DELETE_ALL_SELECTED';

export const CREATE_AUTH_ERROR = 'CREATE_AUTH_ERROR';
export const AUTH = 'AUTH';
export const IS_ADMIN = 'IS_ADMIN';
export const ACCOUNT = 'ACCOUNT';
export const LOGOUT = 'LOGOUT';
