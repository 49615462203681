import { makeStyles } from '@material-ui/core/styles';
import { deepPurple } from '@material-ui/core/colors';

export default makeStyles((theme) => ({
  appBar: {
    marginBottom: '30px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      padding: '10px 50px',
    },
    backgroundColor: '#fafafa',
  },
  navGrid: {
    maxWidth: '1280px',
    margin: '0 auto',
    [theme.breakpoints.up('lg')]: {
      padding: '0 24px',
    },
  },
  heading: {
    // color: 'rgba(0,183,255, 1)',
    color: '#000',
    textDecoration: 'none',
    fontSize: '30px',
    [theme.breakpoints.up('md')]: {
      fontSize: '48px',
    },
  },
  image: {
    marginLeft: '15px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '0',
  },
  profile: {
    marginRight: '15px',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '300px',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
      marginRight: '0',
    },
  },
  userName: {
    display: 'flex',
    alignItems: 'center',
  },
  brandContainer: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    '&:hover': {
      opacity: '0.7',
    },
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
}));
