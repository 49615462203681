import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Grid, Typography, List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import oregon from './../images/oregon.svg';
import idaho from './../images/idaho.svg';
import washington from './../images/washington.svg';

const regions = ['Oregon', 'Washington', 'Idaho'];

const useStyles = makeStyles((theme) => ({
  listGrid: {
    marginTop: '40px',
  },
  stateListItem: {
    minWidth: '200px',
  },
  stateLink: {
    textDecoration: 'none',
    '&:hover': {
      opacity: '0.7',
    },
    fontSize: '24px',
    fontWeight: '600',
    color: '#8a8a8a',
  },
  image: {
    width: '50px',
  },
  tallState: {
    width: '30px'
  },
}));

const regionImages = {
  'oregon': oregon,
  'washington': washington,
  'idaho': idaho,
}

const AllRegions = () => {
  const classes = useStyles();
  return (
    <div>
      <Grid container justify="space-between" alignItems="center" spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h4" align="center">All Regions</Typography>
          <Typography variant="subtitle1" align="center" color="textSecondary">
            Look Up Your River By State
          </Typography>  
        </Grid>
      </Grid>
      <Grid container justify="center" alignItems="center" spacing={1} className={classes.listGrid}>
        <List>
          {regions.map((region, i) => {
            return (
              <ListItem key={`${region}-${i}`} divider disableGutters>
                <Link 
                  to={`/regions/${region.toLowerCase()}`}
                  className={classes.stateLink}
                >
                    <Grid container justify="space-between" alignItems="center" className={classes.stateListItem}>
                      <Grid item xs={4}>
                        <img className={ region === 'Idaho' ? classes.tallState : classes.image } src={regionImages[region.toLowerCase()]} alt={region} /> 
                      </Grid>
                      <Grid item xs={8}>
                        {region}
                      </Grid>
                    </Grid>
                </Link>
              </ListItem>
            );
          })}
        </List>
      </Grid>
    </div>
  );
}

export default AllRegions;