import React from 'react';
import { ListItem, Grid, Hidden,IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  selectButton: {
    textAlign: 'center',
  },
  riverName: {
    fontSize: '14px',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
    },
  },
  riverLi: {
    minHeight: '56px',
  },
  riverRuns: {
    paddingRight: '10px',
    marginTop: '4px',
    fontSize: '10px',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
      marginTop: '0',
    },
  },
  riverNameBold: {
    fontWeight: '500',
    paddingRight: '5px',
  },
  flowDiv: {
    marginTop: '5px',
    [theme.breakpoints.up('md')]: {
      marginTop: '0',
    },
  },
  riverFlow: {
    fontSize: '22px',
    fontWeight: '500',
  },
  greenClass: {
    color: '#0f610f',
  },
  redClass: {
    color: '#C72530',
  },
  userWidth: {
    maxWidth: '90px',
  }
}));

export default function RiverList({ user, isAdmin, page, river, iconCallbackOne, iconCallbackTwo, ListIcon }) {
  const classes = useStyles();

  const userCol = user ? 11 : 12

  function displayFlow(river, user) {
    const useFeet = river.useFeet;

    const userClass = user ? classes.flowDiv : `${classes.userWidth} ${classes.flowDiv}`;

    const greenClass = classes.greenClass;
    const redClass = classes.redClass;
    let flowClass;

    if (!river.cfs) {
      return <Grid className={userClass} item xs={12} sm={4} md={3}></Grid>
    }

    if (useFeet === 'both') {
      return <Grid className={userClass} item xs={6} sm={4} md={3}><span className={classes.riverFlow}>{parseInt(river?.cfs)}</span> CFS / <span className={classes.riverFlow}>{river?.feet}</span> FT</Grid>
    } else if (useFeet === 'yes') {
      return <Grid className={userClass} item xs={6} sm={4} md={3}><span className={classes.riverFlow}>{river?.feet}</span> FT</Grid>
    } else {
      if (!river.runs.length) {
        flowClass = '';
      } else if (parseInt(river.cfs) > parseInt(river.low)) {
        flowClass = greenClass;
      } else {
        flowClass = redClass;
      }
      return <Grid className={userClass} item xs={6} sm={4} md={3}><span className={`${classes.riverFlow} ${flowClass}`}>{parseInt(river?.cfs)}</span> CFS</Grid>
    }
  }

  return (
    <ListItem className={classes.riverLi} divider disableGutters>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={10} sm={userCol}>
          <Grid container justify="space-between" alignItems="center">
            <Grid container item xs={12} justify="space-between" alignItems="center">
              <Grid className={`${classes.riverName} ${classes.riverNameBold}`} item  xs={6} sm={4} md={3}>{river?.name}</Grid>
              <Grid className={classes.riverName} item  xs={6} sm={4} md={3}>{river?.guage}</Grid>
              <Hidden only={['xs', 'sm']}><Grid item  className={classes.riverRuns} md={3}>{river?.runs.map((run,i )=> <div key={`${run}-${i}`}>{run}</div>)}</Grid></Hidden>
              {displayFlow(river, user)}
              <Hidden only={['md','lg','xl']}><Grid item  className={classes.riverRuns} xs={6}><div>{river.runs.length ? <b>Runs:</b> : ''}</div>{river?.runs.map((run,i )=> <div key={`${run}-${i}`}>{run}</div>)}</Grid></Hidden>
            </Grid>
          </Grid>
        </Grid>
        { page === 'region' ? 

        ( user ? <Grid
          item
          xs={2}
          sm={1}
          className={classes.selectButton}
        >
          {isAdmin ? <Link to={`/edit/${river._id}`}>Edit</Link> : ''}
          
          {river.isSelected ? 
            <IconButton 
              aria-label="selected"
              onClick={() => {iconCallbackTwo(river)}}
            >
              <ListIcon style={{ color: '#23D738' }} />
            </IconButton>
          :
            <IconButton 
              aria-label="select"
              onClick={() => {iconCallbackOne(river)}}
            >
              <ListIcon color="disabled" />
            </IconButton>
          }  
        </Grid> : '' )
        :
        (<Grid
          item
          xs={2}
          sm={1}
          className="delete-button"
        >
          <IconButton 
            aria-label="delete"
            onClick={() => iconCallbackOne(river)}
          >
            <ListIcon />
          </IconButton>
        </Grid>)
        }
      </Grid>
    </ListItem>
  );
}