import { DELETE_ALL_SELECTED, UPDATE_SELECTED, DELETE_SELECTED } from '../constants/types';

export const deleteAllSelectedRivers = () => async (dispatch) => {
  try {
    dispatch({ type: DELETE_ALL_SELECTED });
  } catch (error) {
    console.log(error);
  }
};

export const updateSelectedRivers = (riverId) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_SELECTED, payload: riverId });
  } catch (error) {
    console.log(error);
  }
};

export const deleteSelectedRiver = (river) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_SELECTED, payload: river });
  } catch (error) {
    console.log(error);
  }
};