import { FETCH_ALL_RIVERLISTS, CREATE_RIVERLIST, UPDATE_RIVERLIST, DELETE_RIVERLIST_ID } from '../constants/types';

const riverlistReducers = (riverlist = {}, action) => {
  switch (action.type) {
    case FETCH_ALL_RIVERLISTS:
      return action.payload;
    case CREATE_RIVERLIST:
      return [...riverlist, action.payload];
    case UPDATE_RIVERLIST:
      return [...riverlist, action.payload];
    case DELETE_RIVERLIST_ID:
      return [...riverlist, action.payload];
    default:
      return riverlist;
  }
};

export default riverlistReducers;

