import { CREATE_RIVER, FETCH_RIVER, UPDATE_RIVER, DELETE_RIVER } from '../constants/types';

const riverReducer = (river = {}, action) => { 
  switch (action.type) {
    case FETCH_RIVER:
      return action.payload;
    case CREATE_RIVER:
      return action.payload;
    case UPDATE_RIVER:
      return action.payload;
    case DELETE_RIVER:
      return river;
    default:
      return river;
  }
};

export default riverReducer;
