import { DELETE_ALL_SELECTED, UPDATE_SELECTED, DELETE_SELECTED } from '../constants/types';

const selectedRiversReducer = (selectedRivers = [], action) => {
  switch (action.type) {
    case DELETE_ALL_SELECTED:
      return [];
    case UPDATE_SELECTED:
      // console.log(action.payload, 'from the reducer')
      return [...selectedRivers, action.payload];
    case DELETE_SELECTED:
      return [...selectedRivers.filter(river => river.id !== action.payload.id)];
    default:
      return selectedRivers;
  }
};

export default selectedRiversReducer;
