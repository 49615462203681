import axios from 'axios';

const API = axios.create({ baseURL: process.env.REACT_APP_SERVER_URL });
// const API = axios.create({ baseURL: process.env.REACT_APP_DEV_URL });

API.interceptors.request.use((req) => {
  if (localStorage.getItem('profile')) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
  }

  return req;
});

export const fetchRivers = () => API.get('/rivers');

export const fetchRiverList = () => API.get('/riverlist');
export const createRiverList = (riverIdArr) => API.post('/riverlist', riverIdArr);
export const updateRiverList = (id, updatedRiverList) => API.patch(`/riverlist/${id}`, updatedRiverList);
export const deleteRiverListId = (id, riverId) => API.patch(`/riverlist/delete/${id}`, riverId);

export const fetchRiver = (id) => API.get(`/river/${id}`);
export const createRiver = (params) => API.post('/river', params);
export const updateRiver = (id, params) => API.patch(`/river/${id}`, params);
export const deleteRiver = (id, userEmail) => API.post(`/river/delete/${id}`, userEmail);

export const signIn = (formData) => API.post('/user/signin', formData);
export const signUp = (formData) => API.post('/user/signup', formData);
export const forgot = (formData) => API.post('/account/forgot', formData);
export const reset = (formData) => API.post('/account/reset', formData);
export const googleLogin = (formData) => API.post('/user/googlelogin', formData);
