import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Paper, Grid, Typography, Container } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { createRiver } from '../../actions/river';
import useStyles from './styles';
import Input from './Input';
import checkIsAdmin from '../../lib/checkIsAdmin';

const initialState = { name: '', guage: '', apiId: '', api: '', low: '', high: '', desc: '', region: '', runs: '' };

const AddRiver = () => {
  const [form, setForm] = useState(initialState);
  const user = JSON.parse(localStorage.getItem('profile'));
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const error = useSelector(state => state?.auth?.errors?.data);
  const userEmail = user?.result?.email;
  const isAdmin = checkIsAdmin(userEmail);

  if (!isAdmin) {
    history.push('/regions')
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form.runs) {
      form.runs = form.runs.split(',');
    }
    dispatch(createRiver(form, userEmail, history));
  };

  const handleChange = (e) => {
    return setForm({ ...form, [e.target.name]: e.target.value })
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper className={classes.paper} elevation={3}>
        <Typography component="h1" variant="h5">Add River</Typography>
        {error && 
          <Typography color="error" style={{marginTop: '20px'}}>
            Error: {error.message}
          </Typography>     
        }
        <form className={classes.form} onSubmit={handleSubmit} disabled={user ? true : false}>
          <Grid container spacing={2}>
            <Input name="name" label="River Name" handleChange={handleChange} autoFocus />
            <Input name="guage" label="River Guage" handleChange={handleChange} />
            <Input name="apiId" label="Api ID" handleChange={handleChange} />
            <Input name="useFeet" label="Use Feet" handleChange={handleChange} />
            <Input name="api" label="API" handleChange={handleChange} />
            <Input name="low" label="Low" handleChange={handleChange} />
            <Input name="high" label="High" handleChange={handleChange} />
            <Input name="desc" label="Description" handleChange={handleChange} />
            <Input name="region" label="Region" handleChange={handleChange} />
            <Input name="runs" label="Runs" handleChange={handleChange} />
          </Grid>
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            Add River
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default AddRiver;
