import { FETCH_RIVER, CREATE_RIVER, UPDATE_RIVER, DELETE_RIVER } from '../constants/types';
import * as api from '../apis/db.js';

export const getRiver = (id) => async (dispatch) => {
  try {
    const { data } = await api.fetchRiver(id);
    dispatch({ type: FETCH_RIVER, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const createRiver = (river, userEmail, history) => async (dispatch) => {
  try {
    const params = {river, userEmail}; 
    const { data } = await api.createRiver(params);
    dispatch({ type: CREATE_RIVER, payload: data });
    history.push('/regions');
  } catch (error) {
    console.log(error);
  }
};

export const updateRiver = (id, river, userEmail, history) => async (dispatch) => {
  try {
    const params = {river, userEmail};
    const { data } = await api.updateRiver(id, params);
    dispatch({ type: UPDATE_RIVER, payload: data });
    history.goBack();
  } catch (error) {
    console.log(error);
  }
};

export const deleteRiver = (id, userEmail, history) => async (dispatch) => {
  try {
    const userEmailObj = { userEmail }
    const { data } = await api.deleteRiver(id, userEmailObj);
    dispatch({ type: DELETE_RIVER, payload: data });
    history.goBack();
  } catch (error) {
    console.log(error);
  }
};
