import { FETCH_ALL_RIVERS } from '../constants/types';

const riversReducer = (rivers = [], action) => {
  switch (action.type) {
    case FETCH_ALL_RIVERS:
      return action.payload;
    default:
      return rivers;
  }
};

export default riversReducer;

