import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Button, Paper, Grid, Typography, Container } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { reset } from '../../actions/auth';
import useStyles from './styles';
import Input from './Input';

const initialState = { email: '', password: '', confirmPassword: '', resetToken: '' };

const Reset = (props) => {

  const useQuery = () => new URLSearchParams(useLocation().search);

  let query = useQuery();

  const token = query.get("token");

  const [form, setForm] = useState(initialState);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const error = useSelector(state => state?.auth?.errors?.data?.error);
  const message = useSelector(state => state?.auth?.authData?.message);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState('');
  const handleShowPassword = () => setShowPassword(!showPassword);
  const user = JSON.parse(localStorage.getItem('profile'));

  const checkPasswordMatch = (password, confirmPassword) => {
    if (password === confirmPassword) {
      setPasswordMatchError('');
      return true;
    } else {
      setPasswordMatchError('Dang! Passwords do not match.');
      return false;
    }
  }

  useEffect(() => {
    if (token) {
      setForm({ ...form, resetToken: token })
    }
  }, [token])

  useEffect(() => {
    if (user) {
      history.push('/selected')
    }
  }, [])
  
  const handleSubmit = (e) => {
    e.preventDefault();
      const match = checkPasswordMatch(form.password, form.confirmPassword);
      if (match && form.resetToken) {
        dispatch(reset(form, history));
      }
  };

  const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value });

  return (
    <Container component="main" maxWidth="xs">
      {message ?
        <Typography>{message}</Typography>
      :
        <Paper className={classes.paper} elevation={3}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">Reset Password</Typography>
          {passwordMatchError && <Typography color="error">{passwordMatchError}</Typography>}
          {error && 
            <Typography color="error" style={{marginTop: '20px'}}>
              Error: {error}
            </Typography>     
          }
          <form className={classes.form} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Input name="email" label="Email Address" handleChange={handleChange} type="email" />
              <Input name="password" label="Password" handleChange={handleChange} type={showPassword ? 'text' : 'password'} handleShowPassword={handleShowPassword} />
              <Input name="confirmPassword" label="Repeat Password" handleChange={handleChange} type="password" />
            </Grid>
            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
              Reset Password
            </Button>
          </form>
        </Paper>
      }
    </Container>
  );
};

export default Reset;
