import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Button, Paper, Grid, Typography, Container, LinearProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { forgot } from '../../actions/auth';
import useStyles from './styles';
import Input from './Input';

const initialState = {  email: '' };

const Forgot = () => {
  const [form, setForm] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const error = useSelector(state => state?.auth?.errors?.data);
  const message = useSelector(state => state?.auth?.authData?.message);
  const user = JSON.parse(localStorage.getItem('profile'));

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(forgot(form, history));
  };

  const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value });

  useEffect(() => {
    if (user) {
      history.push('/selected')
    }
  }, [])

  useEffect(() => {
    if (message) {
      setLoading(false);
    }
  }, [message])

  return (
    <Container component="main" maxWidth="xs">
      {loading ? <LinearProgress /> : ''}
      {message ?
       <Typography>{message}</Typography>
      :
      <Paper className={classes.paper} elevation={3}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">Get Reset Link</Typography>
        {error && 
          <Typography color="error" style={{marginTop: '20px'}}>
            Error: {error.message}
          </Typography>     
        }
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Input name="email" label="Email Address" handleChange={handleChange} type="email" />
          </Grid>
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            Get Reset Link
          </Button>
        </form>
      </Paper>
      }


    </Container>
  );
};

export default Forgot;
