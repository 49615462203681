import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Button, Paper, Grid, Typography, Container } from '@material-ui/core';
import { useHistory, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { GoogleLogin } from 'react-google-login';
import ReCAPTCHA from 'react-google-recaptcha';
import isEmail from 'validator/lib/isEmail';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import Icon from './icon';
import { signin, signup, googlelogin } from '../../actions/auth';
import useStyles from './styles';
import Input from './Input';

const initialState = { firstName: '', lastName: '', email: '', password: '', confirmPassword: '', reToken: '' };

const SignUp = () => {
  const [form, setForm] = useState(initialState);
  const [isSignup, setIsSignup] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState('');
  const user = JSON.parse(localStorage.getItem('profile'));
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const reRef = useRef();
  const error = useSelector(state => state?.auth?.errors?.data);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => setShowPassword(!showPassword);

  const checkPasswordMatch = (password, confirmPassword) => {
    if (password === confirmPassword) {
      setPasswordMatchError('');
      return true;
    } else {
      setPasswordMatchError('Dang! Passwords do not match.');
      return false;
    }
  }

  const switchMode = () => {
    setForm(initialState);
    setIsSignup((prevIsSignup) => !prevIsSignup);
    setShowPassword(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (emailError) {
      setEmailErrorMessage('Please Enter a Valid Email Address')
      return;
    }
    const reToken = await reRef.current.executeAsync();

    reRef.current.reset();

    if (reToken) {
      setForm({ ...form, reToken });
    }

    if (isSignup) {
      const match = checkPasswordMatch(form.password, form.confirmPassword);
      if (reToken && !form.reToken) {
        form.reToken = reToken;
      }
      if (match && form.reToken && form.email) {
        dispatch(signup(form, history));
      }
    } else {
      if (form.email && form.password) {
        dispatch(signin(form, history));
      }
    }
  };

  const googleSuccess = async (res) => {
    const result = res?.profileObj;
    const gToken = res?.tokenId;


    const googleFormData = {
      name: result.name,
      email: result.email,
      googleId: result.googleId,
      gToken,
    }

    try {
      dispatch(googlelogin(googleFormData, history));
    } catch (error) {
      console.log(error);
    }
  };

  const googleError = (response) => {
    if (response?.details !== "Cookies are not enabled in current environment.") {
      console.log('Google Sign In was unsuccessful. Try again later', response);
    }
  }
  
  const handleChange = (e) => {
    if (e.target.name === "email") {
      if (isEmail(e.target.value)) {
        setEmailError(false);
        setEmailErrorMessage('');
      } else {
        setEmailError(true);
      }
    }
    return setForm({ ...form, [e.target.name]: e.target.value })
  };

  useEffect(() => {
    if (user) {
      history.push('/selected')
    }
  });

  return (
    <Container component="main" maxWidth="xs">
      <Paper className={classes.paper} elevation={3}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">{ isSignup ? 'Sign up' : 'Sign in' }</Typography>
        {passwordMatchError && <Typography color="error">{passwordMatchError}</Typography>}
        {error && 
          <Typography color="error" style={{marginTop: '20px'}}>
            Error: {error.message}
          </Typography>     
        }
        <form className={classes.form} onSubmit={handleSubmit} disabled={user ? true : false}>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_KEY}
            size="invisible"
            ref={reRef}
          />
          <Grid container spacing={2}>
            { isSignup && (
            <>
              <Input name="firstName" label="First Name" handleChange={handleChange} autoFocus half />
              <Input name="lastName" label="Last Name" handleChange={handleChange} half />
            </>
            )}
            <Input name="email" label="Email Address" handleChange={handleChange} type="email" />
            <Typography className={classes.emailError} color="error">{emailErrorMessage}</Typography>
            <Input name="password" label="Password" handleChange={handleChange} type={showPassword ? 'text' : 'password'} handleShowPassword={handleShowPassword} />
            { isSignup && <Input name="confirmPassword" label="Repeat Password" handleChange={handleChange} type="password" /> }
          </Grid>
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            { isSignup ? 'Sign Up' : 'Sign In' }
          </Button>
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            render={(renderProps) => (
              <Button className={classes.googleButton} color="primary" fullWidth onClick={renderProps.onClick} disabled={renderProps.disabled} startIcon={<Icon />} variant="contained">
                Google Sign In
              </Button>
            )}
            onSuccess={googleSuccess}
            onFailure={googleError}
            cookiePolicy="single_host_origin"
          />
          <Grid container justify="flex-end">
            <Grid item>
              <Button onClick={switchMode}>
                { isSignup ? 'Already have an account? Sign in' : "Don't have an account? Sign Up" }
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Button component={Link} to="/forgot">
        Fogot Password? Reset it.
      </Button>
    </Container>
  );
};

export default SignUp;
