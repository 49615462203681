import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { List, Grid, Hidden, Typography, Button, LinearProgress } from '@material-ui/core';
import { CheckCircle, Done, ArrowBack }from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import { getRivers, getRiverList, createRiverList, updateRiverList } from '../actions/rivers';
import { updateSelectedRivers, deleteSelectedRiver } from '../actions/selectedRivers';
import { Link } from 'react-router-dom';

import RiverList from './RiverList';
import checkIsAdmin from '../lib/checkIsAdmin';


const useStyles = makeStyles((theme) => ({
  pageHeader: {
    marginTop: '20px',
    [theme.breakpoints.up('md')]: {
      marginTop: '0',
    },
    marginBottom: '30px',
  },
  header: {
    fontSize: '22px',
    [theme.breakpoints.up('md')]: {
      fontSize: '32px',
      textAlign: 'center',
    },
  },
  message: {
    margin: '20px 0',
  },
  updated: {
    marginBottom: '5px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '0',
    },
  },
  boldH5: {
    fontWeight: '500',
  },
  riverLabel: {
    fontSize: '14px',
    [theme.breakpoints.up('md')]: {
      fontSize: '20px',
    },
  },
  mrXs: {
    paddingLeft: '8px',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '0',
    },
  },
  headerGrid: {
    borderBottom: 'solid 1px black',
    paddingBottom: '5px',
    marginBottom: '10px',
  },
  doneBtn: {
    float: 'right',
  },
  userWidth: {
    maxWidth: '100px',
  }
}));

const RegionRivers = () => {
  const classes = useStyles();
  let { selectedRegion } = useParams();
  const region = `${selectedRegion.charAt(0).toUpperCase()}${selectedRegion.slice(1)}`;
  const [ timeFetched, setTimeFetched ] = useState('');
  const selectedRivers = useSelector((state) => state?.selectedRivers);
  const dbRivers = useSelector((state) => state?.rivers);
  const regionRivers = dbRivers?.filter(river => river.region === region).sort((a, b) => (a.name > b.name) ? 1 : -1);
  const dbRiverList = useSelector((state) => state.riverlist?.[0]?.riverIdArr);
  const dbRiverId = useSelector((state) => state?.riverlist?.[0]?._id);
  const user = JSON.parse(localStorage.getItem('profile'));
  const dispatch = useDispatch();
  const history = useHistory();

  const isAdmin = checkIsAdmin(user?.result?.email);

  let remainingRivers = [];
  if (dbRiverList?.length) {
    remainingRivers = regionRivers?.filter(river => !dbRiverList?.includes(river.apiId));
  } else {
    remainingRivers = regionRivers;
  }

  // console.log(`Remaining rivers - ${remainingRivers?.length}, Database rivers - ${dbRivers?.length}`)

  const currentId = dbRiverId ? dbRiverId : '';

  const userCol = user ? 11 : 12;
  const userClass = user ? '' : classes.userWidth;

  async function onHandleSubmit(e) {
    e.preventDefault();
    const riverDBData = { riverIdArr: [...selectedRivers]};
    // console.log(riverDBData, 'river db data')
    if (!currentId) {

      dispatch(createRiverList({ ...riverDBData }, history));
    } else {

      dispatch(updateRiverList(currentId, { ...riverDBData }, history));
    }
  };


  function onHandleRiverSelect(river) {
    river.isSelected = !river.isSelected;
    // console.log(river, 'say what??????? ')
    const alreadyInDB = dbRiverList?.some(dbRiverId => dbRiverId === river.apiId);
    const alreadySelected = selectedRivers?.some(selRiverId => selRiverId === river.apiId);
    if (alreadySelected || alreadyInDB) {
      return;
    }

    dispatch(updateSelectedRivers(river.apiId));
  }

  function onHandleRiverUnselect(river) {
    river.isSelected = !river.isSelected;
    dispatch(deleteSelectedRiver(river.apiId));
  }

  useEffect(() => {
    dispatch(getRivers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getRiverList());
  }, [dispatch]);

  useEffect(() => {
    if (dbRivers.length) {
      setTimeFetched(dbRivers[0].time);
    }
  }, [dbRivers]);


  return (
    <div>
      <Helmet>
        <title>Paddleflows - {region} Rivers</title>
        <meta
          name="description"
          content={`Current whitewater river flows for kayaking or rafting select ${region} rivers`}
        />
      </Helmet>
      <Grid className={classes.pageHeader} container justify="space-between" alignItems="center" spacing={1}>
        <Grid item xs={12}>
          <Button component={Link} to="/regions" variant="outlined"><ArrowBack />All States</Button>
        </Grid>
        { user ? 
          <Grid item className={classes.message} xs={12}>
            <Typography variant="subtitle1" align="center" color="textSecondary">
              Choose rivers in the order you want them to appear on your list, then click "Done".
            </Typography> 
          </Grid>  
          :
          <Grid item className={classes.message} xs={12}>
            <Typography variant="subtitle1" align="center" color="textSecondary">
              Sign in to choose rivers for your list in the order you want them to appear.
            </Typography>  
          </Grid>     
        }
        {timeFetched ? 
          <Grid item xs={12} sm={3}>
            <Typography variant="body2" className={classes.updated}>Updated: {timeFetched}</Typography>
          </Grid>
        : '' }
        <Grid item xs={6} sm={3}>
          <Typography className={classes.header} variant="h3">{region} Rivers</Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          { user ? 
            <Button onClick={onHandleSubmit} className={classes.doneBtn} variant="contained" color="primary"><Done />Done</Button>
            : ''
          }
        </Grid>
      </Grid>
      {!remainingRivers.length ? <LinearProgress /> : ''}
      <Grid container spacing={2} justify="space-between" className={classes.headerGrid} alignItems="center">
        <Grid item xs={userCol}>
          <Grid container spacing={1} justify="space-between" alignItems="center">
            <Grid item xs={5} sm={4} md={3}><Typography variant="h5" className={`${classes.boldH5} ${classes.riverLabel}`}>River</Typography></Grid>
            <Grid item xs={7} sm={4} md={3}><Typography variant="h5" className={`${classes.boldH5} ${classes.riverLabel} ${classes.mrXs}`}>Gauge</Typography></Grid>
            <Hidden only={['xs', 'sm']}><Grid item md={3}><Typography variant="h5" className={classes.boldH5}>Runs</Typography></Grid></Hidden>
            <Hidden only={['xs']}><Grid item className={userClass} sm={4} md={3}><Typography variant="h5" className={classes.boldH5}>Flow</Typography></Grid></Hidden>
          </Grid>
        </Grid>
      </Grid>
      <List>
        {remainingRivers && remainingRivers.map(river => {
          return (
            <RiverList
              user={user}
              isAdmin={isAdmin}
              key={`${river._id}-reg`}
              page="region"
              river={river}
              iconCallbackOne={onHandleRiverSelect}
              iconCallbackTwo={onHandleRiverUnselect}
              ListIcon={CheckCircle}
            />
          );
        })}
      </List>

    </div>
  );
}

export default RegionRivers;