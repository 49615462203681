import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import TagManager from 'react-gtm-module'
import { Container } from '@material-ui/core';
import AllRegions from './components/AllRegions';
import RegionRivers from './components/RegionRivers';
import SelectedRivers from './components/SelectedRivers';
import Auth from './components/Auth/Auth';
import Navbar from './components/Navbar/Navbar';
import Forgot from './components/Auth/Forgot';
import Reset from './components/Auth/Reset';
import AddRiver from './components/River/AddRiver';
import EditRiver from './components/River/EditRiver';
import { Helmet } from 'react-helmet';

const tagManagerArgs = {
    gtmId: 'GTM-TGH6BD7'
}
 
TagManager.initialize(tagManagerArgs)

const App = () => {
  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="Get current whitewater river levels for kayaking and rafting from a customized list of Oregon, Idaho, and Washington rivers."
        />
        <title>Paddleflows - Custom List of Whitewater River Flows</title>
      </Helmet>
      <BrowserRouter>
        <Navbar />
        <Container maxWidth="lg">
          <Switch>
            <Route exact path="/regions">
              <Redirect to="/" />
            </Route>
            <Route path="/" exact component={AllRegions} />
            <Route path="/regions/:selectedRegion" exact component={RegionRivers} />
            <Route path="/selected" exact component={SelectedRivers} />
            <Route path="/auth" exact component={Auth} />
            <Route path="/forgot" exact component={Forgot} />
            <Route path="/reset" exact component={Reset} />
            <Route path="/add" exact component={AddRiver} />
            <Route path="/edit/:id" exact component={EditRiver} />
          </Switch>
        </Container>
      </BrowserRouter>
    </div>
  );
}

export default App;
