import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Paper, Grid, Typography, Container } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

import { updateRiver, getRiver, deleteRiver } from '../../actions/river';
import useStyles from './styles';
import Input from './Input';
import checkIsAdmin from '../../lib/checkIsAdmin';

const EditRiver = () => {

  const user = JSON.parse(localStorage.getItem('profile'));
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const river = useSelector(state => state?.river);

  const [form, setForm] = useState(river);
  const error = useSelector(state => state?.auth?.errors?.data);

  const userEmail = user?.result?.email;

  const isAdmin = checkIsAdmin(userEmail);

  if (!isAdmin) {
    history.push('/regions')
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (form.runs && form.runs.length > 1) {
      form.runs = form.runs.split(',');
    }
    dispatch(updateRiver(id, form, userEmail, history));
  };

  const handleChange = (e) => {
    return setForm({ ...form, [e.target.name]: e.target.value })
  };

  const handleRiverDelete = (riverId) => {
    dispatch(deleteRiver(riverId, userEmail, history));
  }

  useEffect(() => {
    dispatch(getRiver(id));
  }, [dispatch,id]);

  return (
    <Container component="main" maxWidth="xs">
      <Paper className={classes.paper} elevation={3}>
        <Typography component="h1" variant="h5">Edit River</Typography>
        {error && 
          <Typography color="error" style={{marginTop: '20px'}}>
            Error: {error.message}
          </Typography>     
        }
        {river.name &&
          (<form className={classes.form} onSubmit={handleSubmit} disabled={user ? true : false}>
            <Grid container spacing={2}>
              <Input name="name" label="River Name" handleChange={handleChange} autoFocus value={river.name} />
              <Input name="guage" label="River Guage" handleChange={handleChange} value={river.guage} />
              <Input name="apiId" label="Api ID" handleChange={handleChange} value={river.apiId} />
              <Input name="useFeet" label="Use Feet" handleChange={handleChange} value={river.useFeet} />
              <Input name="api" label="API" handleChange={handleChange} value={river.api} />
              <Input name="low" label="Low" handleChange={handleChange} value={river.low} />
              <Input name="high" label="High" handleChange={handleChange} value={river.high} />
              <Input name="desc" label="Description" handleChange={handleChange} value={river.desc} />
              <Input name="region" label="Region" handleChange={handleChange} value={river.region} />
              <Input name="runs" label="Runs" handleChange={handleChange} value={river.runs.join(', ')} />
            </Grid>
            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
              Update River
            </Button>
            <Button onClick={() => handleRiverDelete(river._id)}>Delete River</Button>
          </form>)
        }

      </Paper>
    </Container>
  );
};

export default EditRiver;
