import React, { useEffect, useState } from 'react';
import { List, Grid, Hidden, Button, Typography, LinearProgress } from '@material-ui/core';
import { HighlightOff, Add } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getRivers, getRiverList, deleteRiverListId } from '../actions/rivers';
import { deleteAllSelectedRivers } from '../actions/selectedRivers';
import { Link } from 'react-router-dom';

import RiverList from './RiverList';

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    marginTop: '20px',
    [theme.breakpoints.up('md')]: {
      marginTop: '0',
    },
    marginBottom: '30px',
  },
  header: {
    fontSize: '28px',
    [theme.breakpoints.up('md')]: {
      fontSize: '32px',
    },
  },
  updated: {
    marginBottom: '5px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '0',
    },
  },
  addBtn: {
    fontSize: '14px',
    padding: '5px 10px',
    [theme.breakpoints.up('md')]: {
      fontSize: '14px',
      padding: '5px 15px',
    },
  },
  boldH5: {
    fontWeight: '500',
  },
  riverLabel: {
    fontSize: '14px',
    [theme.breakpoints.up('md')]: {
      fontSize: '20px',
    },
  },
  mrXs: {
    paddingLeft: '8px',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '0',
    },
  },
  headerGrid: {
    borderBottom: 'solid 1px black',
    paddingBottom: '5px',
    marginBottom: '10px',
  },
}));

const SelectedRivers = () => {
  const classes = useStyles();
  // get values from state
  const dbRivers = useSelector(state => state?.rivers);
  const dbRiverList = useSelector((state) => state?.riverlist?.[0]?.riverIdArr);
  const dbRiverListId = useSelector((state) => state?.riverlist?.[0]?._id);
  const user = JSON.parse(localStorage.getItem('profile'));            
  const history = useHistory();
  const dispatch = useDispatch();

  const [ selectedRivers, setSelectedRivers ] = useState([]);
  const [ timeFetched, setTimeFetched ] = useState('');
  const [ currentId, setCurrentId ] = useState('');
  
  function getSelectedRivers(dbRivers, dbRiverList) {
    if (!dbRivers?.length || !dbRiverList?.length) {
      return;
    }
    // console.log(dbRiverList)
    return dbRiverList?.map(riverId => dbRivers?.find(river => river?.apiId === riverId));
  }


  function onHandleRiverDelete(dbRiver) {
    const filteredArr = selectedRivers?.filter(river => river.apiId !== dbRiver.apiId);
    if (currentId) {
      dispatch(deleteRiverListId(currentId, { riverId: dbRiver.apiId } ));
      setSelectedRivers(filteredArr);
    }
  }

  useEffect(() => {
    dispatch(getRivers());
    dispatch(deleteAllSelectedRivers());
    // console.log('getting rivers and deleting all selected')
  }, [dispatch]);

  useEffect(() => {
    dispatch(getRiverList());
    // console.log('getting getting river list')
  }, [dispatch]);


  useEffect(() => {
    const selectedDBRivers = getSelectedRivers(dbRivers, dbRiverList);
    if (selectedDBRivers) {
      setSelectedRivers(selectedDBRivers);
    }
    // console.log('sorting out the rivers')
  }, [dbRivers, dbRiverList]);


  useEffect(() => {
    if (dbRiverListId) {
      setCurrentId(dbRiverListId);
      // console.log('setting river id: ', dbRiverListId)
    }
  }, [dbRiverListId]);

  useEffect(() => {
    if (dbRivers.length) {
      setTimeFetched(dbRivers[0].time);
    }
  }, [dbRivers]);

    useEffect(() => {
    if (!user) {
      history.push('/regions')
    }
  }, [])

  // console.log(selectedRivers)
 
  return (
    <div>
      <Grid className={classes.pageHeader} container justify="space-between" alignItems="center">
        <Grid item>
          {timeFetched ? <Typography className={classes.updated} variant="body2" align="center">Updated: {timeFetched}</Typography> : '' }
        </Grid>
        <Grid item>
          <Typography className={classes.header} variant="h3" align="center">My Rivers</Typography>
        </Grid>
        <Grid item>
          <Button className={classes.addBtn} component={Link} to="/regions" variant="outlined"><Add />Add More</Button>
        </Grid>
      </Grid>
      {!selectedRivers?.length ? <LinearProgress /> : ''}
      <Grid container spacing={1} justify="space-between" className={classes.headerGrid} alignItems="center">
        <Grid item className="river-data" xs={11}>
            <Grid container spacing={1} justify="space-between" alignItems="center">
              <Grid item xs={5} sm={4} md={3}><Typography variant="h5" className={`${classes.boldH5} ${classes.riverLabel}`}>River</Typography></Grid>
              <Grid item xs={7} sm={4} md={3}><Typography variant="h5" className={`${classes.boldH5} ${classes.riverLabel} ${classes.mrXs}`}>Gauge</Typography></Grid>
              <Hidden only={['xs', 'sm']}><Grid item md={3}><Typography variant="h5" className={classes.boldH5}>Runs</Typography></Grid></Hidden>
              <Hidden only={['xs']}><Grid item sm={4} md={3}><Typography variant="h5" className={classes.boldH5}>Flow</Typography></Grid></Hidden>
            </Grid>
        </Grid>
      </Grid>
      <List>
        {selectedRivers && selectedRivers?.map(river => {
          // console.log(river, 'hey river from the quiver')
          return (
            <RiverList
              user={user}
              key={`${river?._id}-sel`}
              page="selected"
              river={river}
              iconCallbackOne={onHandleRiverDelete}
              ListIcon={HighlightOff}
            />
          );
        })}
      </List>

    </div>
  );
}

export default SelectedRivers;