import * as actionType from '../constants/types';

const authReducer = (state = { authData: null, isAdmin: false, loading: false, errors: null, rivers: [], selectedRivers: [] }, action) => {
  switch (action.type) {
    case actionType.AUTH:
      localStorage.setItem('profile', JSON.stringify({ ...action?.data }));

      return { ...state, authData: action.data, loading: false, errors: null };
    case actionType.IS_ADMIN:

      return { ...state, isAdmin: action.payload };
    case actionType.CREATE_AUTH_ERROR:

      return { ...state, loading: false, errors: action.response };
    case actionType.LOGOUT:
      localStorage.clear();

      return { ...state, authData: null, isAdmin: false, loading: false, errors: null, rivers: [], selectedRivers: [] };
    case actionType.ACCOUNT:

      // console.log(action.data, 'yo data from the schmata')
      return {...state, authData: action.data};
    default:
      return state;
  }
};

export default authReducer;
