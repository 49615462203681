import React, { useState, useEffect } from 'react';
import { Typography, Toolbar, Grid, Hidden, Button } from '@material-ui/core';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import decode from 'jwt-decode';

import kayak from '../../images/kayak.png';
import * as actionType from '../../constants/types';
import useStyles from './styles';

const Navbar = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const dbRiverList = useSelector((state) => state?.riverlist?.[0]?.riverIdArr);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();

  const logout = () => {
    dispatch({ type: actionType.LOGOUT });

    history.push('/auth');

    setUser(null);
  };

  useEffect(() => {
    const token = user?.token;

    if (token) {
      const decodedToken = decode(token);

      if (decodedToken.exp * 1000 < new Date().getTime()) logout();
    }

    setUser(JSON.parse(localStorage.getItem('profile')));
  }, [location]);

  // console.log(user, 'hey the user')

  return (
    <div className={classes.appBar} position="static" color="inherit">
      <Grid container spacing={1} className={classes.navGrid}>
        <Grid item xs={6}>
          <Link to={dbRiverList?.length ? '/selected' : '/regions'} className={classes.brandContainer}>
            <Hidden only={['xs', 'sm']}><Typography className={classes.heading} variant="h3" align="center" color="textPrimary">Paddle Flows</Typography></Hidden>
            <img className={classes.image} src={kayak} alt="kayaker in a kayak" height="60" />
          </Link>
        </Grid>
        <Grid item xs={6}>
          <Toolbar className={classes.toolbar}>
            {user?.result ? (
              <div className={classes.profile}>
                <Hidden only={['xs', 'sm']}>
                  <Typography className={classes.userName} variant="h6">Howdy, {user?.result.name.split(' ')[0]}!</Typography>
                </Hidden>
                  <Button variant="contained" className={classes.logout} color="secondary" onClick={logout}>Logout</Button>
              </div>
            ) : (
              window.location.pathname !== '/auth' ?
                <Button component={Link} to="/auth" variant="contained" color="primary">Sign In</Button> : ''
            )}
          </Toolbar>
        </Grid>
      </Grid>
    </div>
  );
};

export default Navbar;
